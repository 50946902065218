/**
 * Copyright 2023-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useState } from "react";
import { RowSelectionState } from "@tanstack/table-core";
import dayjs from "dayjs";
import { Col, Row } from "react-awesome-styled-grid";
import { When } from "react-if";
import {
  Box,
  FlexContainer,
  theme,
  ExtendedTooltip,
  Text,
} from "@nordcloud/gnui";
import {
  IncomingEvent,
  IncomingEventStatus,
  PlanAction,
} from "~/generated/graphql";
import { ExpandIcon } from "~/components";
import { TooltipText } from "~/components/Styled/TooltipText";
import { TableWrapper } from "~/components/Tables";
import { NameWithLink } from "~/components/Tables/cells/NameWithLink";
import { dateFormat } from "~/constants";
import { useToggle } from "~/hooks";
import { ROUTES } from "~/routing/routes";
import { isNotEmpty } from "~/tools";
import { RefetchOptions, convertMinutesToTime } from "~/views/events/helpers";
import { EventActions } from "../EventsDetails/components/EventActions";
import { GridColumns } from "../styles";
import { planActionsColumns } from "./PlanActionsColumns";
import { TooltipIconStatus } from "./TooltipIconStatus";
import { EntityType } from "./types";

type Props = {
  event: IncomingEvent;
};

export function IncomingEventItem({ event }: Props) {
  const [isExpanded, toggleIsExpanded] = useToggle();
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});

  const endTimeTZ = dayjs
    .tz(event?.estimatedEndTime ?? "", event?.scheduleTimezone ?? "")
    .format(dateFormat.dayMonthYearHourMinute);

  const startTimeTZ = dayjs
    .tz(event?.startTime ?? "", event?.scheduleTimezone ?? "")
    .format(dateFormat.dayMonthYearHourMinute);

  const duration = dayjs(event?.estimatedEndTime).diff(
    event?.startTime,
    "minutes"
  );

  return (
    <Box spacing="spacing02" boxStyle="lightGrey" data-testid="event-row">
      <Row data-testid={event?.name}>
        <Col>
          <GridColumns>
            <FlexContainer justifyContent="center" alignItems="center">
              <When condition={isNotEmpty(event?.plan?.planActions ?? [])}>
                <ExpandIcon
                  name="chevronDown"
                  title="chevronDown"
                  isExpanded={isExpanded}
                  onClick={toggleIsExpanded}
                />
              </When>
            </FlexContainer>
            <TooltipIconStatus
              status={event?.status}
              type={EntityType.FUTURE_EVENT}
            />
            <NameWithLink
              name={event?.name ?? ""}
              link={ROUTES.events.generateDetails(event?.id ?? "")}
            />
            <FlexContainer alignItems="center">
              <ExtendedTooltip
                caption={
                  <>
                    Plan Time: {startTimeTZ}
                    <br />
                    Timezone: {event?.scheduleTimezone}
                  </>
                }
              >
                <TooltipText
                  size="md"
                  tag="span"
                  mr={theme.spacing.spacing01}
                  weight="regular"
                  color={theme.color.text.text01}
                >
                  {dayjs(event?.startTime).format(
                    dateFormat.dayMonthYearHourMinute
                  )}
                </TooltipText>
              </ExtendedTooltip>
            </FlexContainer>
            <FlexContainer alignItems="center">
              <ExtendedTooltip
                caption={
                  <>
                    Plan Time: {endTimeTZ}
                    <br />
                    Timezone: {event?.scheduleTimezone}
                  </>
                }
              >
                <TooltipText
                  size="md"
                  tag="span"
                  mr={theme.spacing.spacing01}
                  weight="regular"
                  color={theme.color.text.text01}
                >
                  {dayjs(event?.estimatedEndTime).format(
                    dateFormat.dayMonthYearHourMinute
                  )}
                </TooltipText>
              </ExtendedTooltip>
            </FlexContainer>
            <FlexContainer alignItems="center">
              <Text
                size="md"
                tag="span"
                mr={theme.spacing.spacing01}
                weight="regular"
                color={theme.color.text.text01}
              >
                {convertMinutesToTime(duration)}
              </Text>
            </FlexContainer>
            <div />
            <FlexContainer alignItems="center" justifyContent="flex-end">
              <EventActions
                eventId={event?.id}
                status={IncomingEventStatus.Upcoming}
                refetchQueries={RefetchOptions.INCOMING_EVENTS}
              />
            </FlexContainer>
          </GridColumns>
          <When condition={isExpanded}>
            <TableWrapper<PlanAction>
              loading={false}
              data={event?.plan?.planActions ?? []}
              columns={planActionsColumns({ status: event.status })}
              rowSelection={rowSelection}
              selectedRowsHandler={() => null}
              setRowSelection={setRowSelection}
              stripped
            />
          </When>
        </Col>
      </Row>
    </Box>
  );
}
