/**
 * Copyright 2022-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { createColumnHelper } from "@tanstack/react-table";
import { theme } from "@nordcloud/gnui";
import {
  EventStatus,
  IncomingEventStatus,
  PlanAction,
} from "~/generated/graphql";
import { MediumIconColumn, SmallIconColumn } from "~/components/Tables/styles";
import { TooltipIconStatus } from "./TooltipIconStatus";
import { EntityType } from "./types";
import { getTotalResourceCount } from "./utils";

const columnHelper = createColumnHelper<PlanAction>();

type Props = {
  status: EventStatus | IncomingEventStatus;
};
export function planActionsColumns({ status }: Props) {
  return [
    columnHelper.accessor(() => null, {
      header: "",
      id: "dummy",
      meta: SmallIconColumn,
    }),
    columnHelper.accessor(() => null, {
      header: "",
      id: "state",
      cell: () => (
        <TooltipIconStatus status={status} type={EntityType.ACTION} />
      ),
      meta: MediumIconColumn,
    }),
    columnHelper.accessor(() => null, {
      header: "Action Name",
      cell: ({ row }) => {
        return <div>{row.original.action?.name}</div>;
      },
      meta: {
        css: {
          width: "100%",
          minWidth: "20rem",
          padding: theme.spacing.spacing03,
        },
      },
    }),
    columnHelper.accessor(() => null, {
      header: "Number of resources",
      cell: ({ row }) => {
        return <div>{getTotalResourceCount(row.original.resourceGroups)}</div>;
      },
      meta: {
        css: {
          minWidth: "8.75rem",
          padding: theme.spacing.spacing00,
        },
      },
    }),
    columnHelper.accessor(() => null, {
      header: "Successful",
      cell: () => "-",
      meta: {
        css: {
          minWidth: "8.75rem",
          padding: theme.spacing.spacing00,
        },
      },
    }),
    columnHelper.accessor(() => null, {
      header: "Unsuccessful",
      cell: () => "-",
      meta: {
        css: {
          minWidth: "8.75rem",
          padding: theme.spacing.spacing03,
        },
      },
    }),
  ] as never;
}
