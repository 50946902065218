/**
 * Copyright 2023-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import {
  EventActionAttempt,
  ExecutionStatus,
  BatchActionFragment,
  EventActionFragment,
  EventAction,
} from "~/generated/graphql";
import { ToggleItem } from "~/components/ToggleItemBox";

export enum Period {
  week = "WEEK",
  month = "MONTH",
  year = "YEAR",
  range = "RANGE",
}
export enum EventType {
  pastCurrent = "PAST_CURRENT",
  future = "FUTURE",
}

export enum EventsFilterFields {
  startDate = "startDate",
  endDate = "endDate",
}

export type EventsFiltersType = {
  startDate?: string;
  endDate?: string;
};

export type DateRange = {
  startDate: string;
  endDate: string;
};

export type Item = ToggleItem & {
  onSelect?: (value: EventActionAttempt) => void;
};

export enum EntityType {
  EVENT = "Event",
  FUTURE_EVENT = "FutureEvent",
  ACTION = "Action",
  ACTION_BATCH = "ActionBatch",
}

export type EventEntity = {
  id: string;
  name: string;
  action?: EventActionFragment["action"];
  scope?: EventActionFragment["scope"];
  order?: EventActionFragment["order"];
  attempts?: EventActionAttempt[];
  executionPolicy?: EventActionFragment["executionPolicy"];
  windowDuration?: EventActionFragment["windowDuration"];
  skipWindow?: EventActionFragment["skipWindow"];
  status?: ExecutionStatus;
  runInSequence?: EventAction["runInSequence"];
  globalState?:
    | EventActionFragment["globalState"]
    | BatchActionFragment["globalState"];
  actions?: BatchActionFragment[];
  subRows?: BatchActionFragment[];
  resourceStates?:
    | BatchActionFragment["resourceStates"]
    | EventActionFragment["resourceStates"];
  resourceGroups?:
    | BatchActionFragment["resourceGroups"]
    | EventActionFragment["resourceGroups"];
};

export type EventEntityWithOutputUri = EventEntity &
  EventEntity["globalState"] & {
    globalState: {
      outputUri?: string;
    };
  };
